import React, { FC } from "react";
import { Link, graphql, PageProps } from "gatsby";

import Layout from "components/Layout";
import Seo from "components/Seo";

const Index: FC<PageProps<GatsbyTypes.GetPostsQuery>> = (props) => {
    const { data, location } = props;
    const posts = data.allMarkdownRemark.nodes;

    return (
        <Layout location={ location }>
            <Seo />

            <ul>
                { posts.map((post, key) => {
                    const url   = post.fields?.slug ?? "";
                    const title = post.frontmatter?.title || post.fields?.slug;
                    const date  = post.frontmatter?.date;

                    return (
                        <li key={ key }>
                            <Link to={ url } className={`block p-4 ${posts.length - 1 === key ? "" : "border-b border-gray-50"}`}>
                                <h2 className="text-xl truncate">
                                    { title }
                                </h2>
                                <span className="text-sm">
                                    { date }
                                </span>
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </Layout>
    );
};
export default Index;


export const query = graphql`
    query GetPosts {
        site {
            siteMetadata {
                title
            }
        }
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
            nodes {
                fields {
                    slug
                }
                frontmatter {
                    date(formatString: "YYYY年MM月DD日")
                    title
                }
            }
        }
    }
`;